import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subject, BehaviorSubject } from 'rxjs';

export enum MediaSize {
    xs = 1,
    sm = 2,
    md = 3,
    lg = 4,
    xl = 5
}

@Injectable({ providedIn: 'root' })
export class MediaService {
    private activeMediaQuery = '';
    public onScreenMedia: BehaviorSubject<MediaSize> = new BehaviorSubject(MediaSize.lg);
    constructor(mediaObserver: MediaObserver) {
        mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;


            switch (this.activeMediaQuery) {
                case 'xs':
                    this.onScreenMedia.next(MediaSize.xs);
                    break;
                case 'sm':
                    this.onScreenMedia.next(MediaSize.sm);
                    break;
                case 'md':
                    this.onScreenMedia.next(MediaSize.md);
                    break;
                case 'lg':
                    this.onScreenMedia.next(MediaSize.lg);
                    break;
                case 'xl':
                    this.onScreenMedia.next(MediaSize.xl);
                    break;
                default:
            }

        });
    }

}