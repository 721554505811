<div *ngIf="activeMedia > size.md"
  fxLayout="column"
  fxLayoutAlign="start start">
  <div class="header-container">
    <img class="profile-image"
      [src]="imgUrl" />
    <blockquote fxLayout="column"
      fxLayoutAlign="center start">
      <span class="name-text">
        David's blog
      </span>
      <div fxFlex="10px"></div>
      <span class="blog-sub-title">
        {{subText}}
      </span>
    </blockquote>
  </div>
</div>

<div fxLayout="column"
  fxLayoutAlign="start center"
  *ngIf="activeMedia <= size.md">
  <div class="header-container-small">
    <img class="profile-image-small"
      [src]="imgUrl" />
  </div>
  <blockquote fxLayout="column"
    fxLayoutAlign="center center">
    <span class="name-text-small">
      David's blog
    </span>
    <div fxFlex="10px"></div>
    <span class="blog-sub-title-small">
      {{subText}}
    </span>
  </blockquote>
</div>