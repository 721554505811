<div class="container"
    [ngClass]="{ 'container-border': displayBorder }"
    fxLayout="column"
    fxLayoutAlign="center center"
    (drop)="dropped($event)"
    (dragover)="draggingOver($event)"
    *ngIf="allowDragAndDrop">
    <form method="post"
        action=""
        enctype="multipart/form-data">
        <div class="content-wrapper"
            fxLayout="column"
            fxLayoutAlign="space-around center">
            <span *ngIf="!uploadedfile">
                <mat-icon class="upload-icon">cloud_upload</mat-icon>
            </span>
            <span *ngIf="!uploadedfile"
                class="directions-text">{{ placeholder }}</span>
            <span class="error"
                *ngIf="errorMessage">{{ errorMessage }}</span>
            <img class="preview-image"
                *ngIf="previewFile && displayPreview"
                src="{{ previewFile.src }}" />
            <span class="file-name"
                *ngIf="uploadedfile && previewFile">{{ uploadedfile.name }}</span>
            <input class="box__file"
                type="file"
                name="files[]"
                #file
                (change)="selectFile($event)" />
            <label for="file">
                <button mat-raised-button
                    [color]="color"
                    (click)="file.click(); file.value= ''; errorMessage= undefined">{{ buttonText }}</button>
            </label>
        </div>
    </form>
</div>
<div class="container"
    [ngClass]="{ 'container-border': displayBorder }"
    fxLayout="row"
    fxLayoutAlign="start center"
    *ngIf="!allowDragAndDrop">
    <label for="file">
        <button mat-raised-button
            [color]="color"
            (click)="file.click(); errorMessage= undefined">{{ buttonText }}</button>
    </label>
    <!-- <span *ngIf="!uploadedfile && allowDragAndDrop"
        class="directions-text-inline">{{ placeholder }}</span>
    <span class="file-name-inline"
        *ngIf="uploadedfile">{{ uploadedfile.name }}</span>
    <span class="error-inline"
        *ngIf="errorMessage">{{ errorMessage }}</span> -->
    <input class="box__file"
        type="file"
        name="files[]"
        #file
        (change)="selectFile($event)" />
</div>