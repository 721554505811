<div class="global-layout">
    <div class="top-section">
        <app-header></app-header>
        <app-quote-area class="quote-area"></app-quote-area>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div ngClass.gt-md="skills">
            <app-skills></app-skills>
        </div>
        <div class="w-full">
            <app-career-history></app-career-history>
        </div>
    </div>
    <app-contact *ngIf="activeMedia <= size.md"></app-contact>
</div>