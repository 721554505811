<div fxLayout="row"
  class="quite-container"
  *ngIf="activeMedia >= size.md">
  <div fxLayout="column"
    class="content-wrapper"
    fxLayoutAlign="center start">
    <div class="quote-body">
      <img class="quote-img"
        src="../../../assets/quote.PNG" />
      <blockquote class="blockquote-item">
        David is an innovative disruptor, devoted to developing solutions that embrace effciency. David
        previously served as Head of I.T. & Strategic Projects at Investec Private Bank. David’s mainstay is to
        inspire global teams with optimism to focus on goal-orientated outcomes. With strong character
        being formed in the South African military and competitive sports playing rugby, David honed his
        mental and physical talents to win for his team. These skills and acumen adapted into the business
        world where David is a serial entrepreneur who executes strategic plans.
      </blockquote>
    </div>
    <div class="quote-links">
      <a target="_blank"
        class="link"
        href="https://thesiliconreview.com/magazine/profile/the-leading-digital-marketing-lifestyle-and-mobile-payment-platform-zapper">read more</a>
    </div>
    <div class="writer-text">
      - the silicon review
    </div>
  </div>
</div>

<div *ngIf="activeMedia < size.md"
  class="quite-container-small">
  <div fxLayout="column"
    fxLayoutAlign="center start">
    <div class="quote-body-small">
      <blockquote>
        David is an innovative disruptor, devoted to developing solutions that embrace effciency. David
        previously served as Head of I.T. & Strategic Projects at Investec Private Bank. David’s mainstay is to
        inspire global teams with optimism to focus on goal-orientated outcomes. With strong character
        being formed in the South African military and competitive sports playing rugby, David honed his
        mental and physical talents to win for his team. These skills and acumen adapted into the business
        world where David is a serial entrepreneur who executes strategic plans.
      </blockquote>
    </div>
    <div class="quote-links-small">
      <a target="_blank"
        class="link"
        href="https://thesiliconreview.com/magazine/profile/the-leading-digital-marketing-lifestyle-and-mobile-payment-platform-zapper">read more</a>
    </div>
    <div class="writer-text-small">
      - the silicon review
    </div>
  </div>
</div>