import { Component, Input } from "@angular/core";
import { MediaSize, MediaService } from 'src/app/services/media.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: "app-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.scss"],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ opacity: 1 })),
      // transition(':enter', [
      //   style({ transform: 'translateX(-200%)' }),
      //   animate('0.5s 00ms ease-in')
      // ]),
      // transition(':leave', [
      //   animate('0.3s ease-out', style({ transform: 'translateX(200%)' }))
      // ])
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s 00ms ease-in')
      ]),
      transition(':leave', [
        animate('1s ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SkillsComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;
  public state = 'poes';
  @Input() inView = false;
  constructor(private mediaService: MediaService) {

  }

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
  }

  triggerAnimations() {
    this.state = 'flyIn'
  }

} 
