import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MediaService, MediaSize } from 'src/app/services/media.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;
  constructor(private mediaService: MediaService, private router: Router) {}

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
  }

  openLinkedIn() {
    window.open('https://www.linkedin.com/in/daviddevilliers/', '_blank');
  }

  goToBlog() {
    this.router.navigate(['blog']);
  }

  downloadCV() {
    window.open('/assets/David-de-villiers-cv.pdf', '_blank');
  }
}
