<div class="container">
    <div class="side-div primary"></div>
    <div class="side-div secondary"></div>
    <div class="side-div tertiary"></div>
    <!-- <div class="login-card">
        <button mat-stroked-button
            class="login-button"
            [routerLink]="['/']">
            <mat-icon>home</mat-icon>&nbsp;Home
        </button>

        <mat-form-field floatPlaceholder="never">
            <mat-icon matPrefix>person</mat-icon>
            <input matInput
                [(ngModel)]="username"
                type="text"
                placeholder="User Name"
                class="validate" />
        </mat-form-field>
        <br />
        <mat-form-field floatPlaceholder="never">
            <mat-icon matPrefix>lock</mat-icon>
            <input matInput
                [(ngModel)]="password"
                type="password"
                placeholder="Password"
                class="validate" />
        </mat-form-field>
        <div fxFlex="50px"></div>
        <button (click)="login()"
            class="submit-button"
            mat-raised-button>Sign in</button>



    </div>
 -->




    <div class="login-card-container"
        fxLayout="column"
        fxLayout.xs="column"
        fxLayoutAlign="center center">
        <div fxLayout="column"
            class="login-form">
            <span class="login-text">Login</span>
            <div fxFlex="20px"></div>
            <mat-form-field floatPlaceholder="never">
                <mat-icon matPrefix>person</mat-icon>
                <input matInput
                    [(ngModel)]="username"
                    type="text"
                    placeholder="User Name"
                    class="validate" />
            </mat-form-field>
            <div fxFlex="20px"></div>
            <mat-form-field floatPlaceholder="never">
                <mat-icon matPrefix>lock</mat-icon>
                <input matInput
                    [(ngModel)]="password"
                    type="password"
                    placeholder="Password"
                    class="validate" />
            </mat-form-field>

            <div fxFlex="20px"></div>
            <button (click)="login()"
                class="submit-button"
                mat-raised-button>Sign in</button>
            <div fxFlex="20px"></div>
            <button [routerLink]="['/']"
                mat-button>Home</button>
        </div>
    </div>




</div>