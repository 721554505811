import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { material, sharedComponents, sharedEntryComponents } from './app.declarations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { RouterModule } from '@angular/router';
import { AppearDirective } from './directives/appear.directive';
import { AuthService } from './services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { FirebaseService } from './services/firebase.service';
import * as firebase from 'firebase';

// this is needed for the image upload
firebase.initializeApp(environment.firebase);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    FlexLayoutModule,
    ...material,
    FormsModule,

    AngularFireModule.initializeApp(environment.firebase, 'david-de-villiers-portfolio'),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    CKEditorModule
  ],
  declarations: [AppComponent, ...sharedComponents, AppearDirective],
  entryComponents: [...sharedEntryComponents],
  exports: [...sharedComponents, ...material],
  providers: [AngularFireAuth, AuthService, FirebaseService, AngularFireAuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
