import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from '../../models/blogs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user: User;

  constructor(public router: Router, public ngZone: NgZone, public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
    });
  }

  async login(email: string, password: string) {
    await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    this.router.navigate(['blog-edit']);
  }

  // Firebase Logout
  signOut() {
    return this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['login']);
    });
  }
}
