import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';
import { BlogEditComponent } from './components/blog-edit/blog-edit.component';
import { BlogItemComponent } from './components/blog-item/blog-item.component';
import { BlogComponent } from './components/blog/blog.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent
  },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'blog-edit',
    component: BlogEditComponent,
    canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:id/view',
    component: BlogItemComponent
  }
];
