import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'file-uploader',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() color = 'primary';
  @Input() maxFileSize: number;
  @Input() displayPreview = true;
  @Input() allowDragAndDrop = true;
  @Input() placeholder = 'Choose a file or drop the file here.';
  @Input() displayBorder = true;
  @Input() accept: string;
  @Input() returnFile = false;
  @Input() buttonText = 'Choose File';

  @Output() fileAdded: EventEmitter<any> = new EventEmitter();

  uploadedfile: any;
  previewFile: any;
  errorMessage: string;
  acceptedFormats: string[] = [];

  constructor() {}

  ngOnInit() {
    if (this.accept) {
      this.acceptedFormats = this.accept.split(',');
    }
  }

  dropped(event) {
    event.preventDefault();
    event.stopPropagation();

    const firstFile = event.dataTransfer.files[0];

    this.handleFile(firstFile);
  }

  draggingOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private setUpReader(rawFile): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          event.stopPropagation();

          const file = {
            src: event.target.result
          };

          this.previewFile = file;

          if (this.returnFile) {
            this.fileAdded.emit(rawFile);
          } else {
            this.fileAdded.emit(this.previewFile);
          }

          this.errorMessage = undefined;
          this.uploadedfile = rawFile;

          return resolve(true);
        };

        const fileType = rawFile.type && rawFile.type.length > 0 ? rawFile.type.split('/')[1] : rawFile.name.replace(/^.*\./, '');
        if (fileType) {
          if (this.acceptedFormats.length > 0) {
            if (this.acceptedFormats.some(x => x === fileType)) {
              reader.readAsDataURL(rawFile);
            } else {
              this.errorMessage = 'Invalid File type';
            }
          } else {
            reader.readAsDataURL(rawFile);
          }
        } else {
          this.errorMessage = 'Invalid File type';
        }
      } catch (error) {
        return reject(false);
      }
    });
  }

  private handleFile(file: any) {
    if (file) {
      if (this.maxFileSize) {
        if (file.size > this.maxFileSize) {
          this.errorMessage = 'File is too large';
        } else {
          this.setUpReader(file);
        }
      } else {
        this.setUpReader(file);
      }
    }
  }

  public selectFile(e) {
    this.handleFile(e.target.files[0]);
  }
}
