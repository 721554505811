<div class="container">
    <mat-sidenav-container class="container">
        <mat-sidenav mode="side" opened class="side-nav">
            <div class="panel-content">
                <div class="action-bar">
                    <div class="title-text">Blogs</div>
                    <button mat-icon-button color="primary" (click)="createNewBlog()">
                        <mat-icon matTooltip="Create new blog">add</mat-icon>
                    </button>
                </div>
                <mat-selection-list [multiple]="false">
                    <mat-list-option checkboxPosition="none" [value]="blog" *ngFor="let blog of blogs;" (click)="setSelectedBlog(blog)">
                        <div class="blog-item">
                            <div>{{ blog.title }}</div>
                            <mat-icon (click)="deleteBlog(blog);$event.stopPropagation()">delete</mat-icon>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
                <div class="action-bar bottom-align">
                    <button mat-flat-button color="primary" (click)="signOut()">
                        Sign out
                    </button>
                </div>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="action-bar" *ngIf="selectedBlog">
                <button mat-stroked-button color="primary" (click)="updateSelectedBlog()">
                    Save
                </button>
                <button mat-stroked-button color="primary" (click)="clearSelectedBlog()" class="margin-left">
                    Cancel
                </button>
            </div>
            <div class="padded-content " *ngIf="this.selectedBlog && this.selectedBlog?.imgUrl">
                <img [src]="this.selectedBlog.imgUrl" class="blog-image">
                <div class="action-bar">
                    <button mat-stroked-button color="primary" (click)="this.selectedBlog.imgUrl = ''">Change Image</button>
                </div>
            </div>
            <div class="padded-content" *ngIf="this.selectedBlog && !this.selectedBlog?.imgUrl">
                <file-uploader [returnFile]="true" (fileAdded)="fileAdded($event)"></file-uploader>
            </div>
            <div class="padded-content" *ngIf="selectedBlog">
                <mat-form-field class="example-full-width padding-top" appearance="outline">
                    <mat-label>Title</mat-label>
                    <input matInput [(ngModel)]="selectedBlog.title">
                </mat-form-field>
                <div>
                    <ckeditor #bodyEditor [editor]="Editor" [(ngModel)]="selectedBlog.body"></ckeditor>
                </div>
                <div class="shared-links-wrapper padding-top">
                    <div *ngFor="let sharedLink of selectedBlog.sharedLinks;let i = index;trackBy:trackByIndex;">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Link</mat-label>
                            <input matInput [(ngModel)]="selectedBlog.sharedLinks[i]">
                            <button mat-button *ngIf="sharedLink" matSuffix mat-icon-button aria-label="Clear" (click)="removeSharedLink(sharedLink)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                </div>
            </div>
            <div class="action-bar" *ngIf="selectedBlog">
                <button mat-stroked-button color="primary" (click)="addNewSharedLink()">
                    <mat-icon>add</mat-icon>Add link
                </button>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>