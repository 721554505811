<div fxLayout="column" fxLayoutAlign="start stretch" class="offer-container" *ngIf="activeMedia >= size.md">
     <span class=" header-text">Career History</span>

     <div *ngFor="let item of historyItems" [ngStyle]="{'border-top':!item.isOpen ? '2px Solid Black':'2px Solid white'}" class="history-item">
          <span class="history-date">{{item.date | uppercase}}</span>
          <div class="history-title">{{item.title}} <div (click)="toggleIsOpen(item)" class="math-icon">{{item.isOpen ? '-' : '+'}}</div>
          </div>
          <div class="history-description" [innerHTML]="item.description" [@detailExpand]="item.isOpen ? 'expanded' : 'collapsed'"></div>
     </div>

     <div class="education-wrapper">
          <span class="header-text">Education and Qualifications</span>
          <div class="education-items-wrapper">
               <div *ngFor="let edu of education" class="education-item">
                    <div class="education-year">{{ edu.year }}</div>
                    <div [innerHTML]="edu.description"></div>
               </div>
          </div>
     </div>

</div>

<div fxLayout="column" fxLayoutAlign="start stretch" class="offer-container-small" *ngIf="activeMedia < size.md">
     <span class="header-text-small">Career History</span>

     <div *ngFor="let item of historyItems" [ngStyle]="{'border-top':!item.isOpen ? '2px Solid Black':'2px Solid white'}" class="history-item-small">
          <span class="history-date-small">{{item.date | uppercase}}</span>
          <div class="history-title-small">{{item.title}}
               <div (click)="toggleIsOpen(item)" class="math-icon-small">{{item.isOpen ? '-' : '+'}}</div>
          </div>
          <div class="history-description-small" [innerHTML]="item.description" [@detailExpand]="item.isOpen ? 'expanded' : 'collapsed'"></div>
     </div>

     <div class="education-wrapper-small">
          <span class="header-text-small">Education and Qualifications</span>
          <div class="education-items-wrapper-small">
               <div *ngFor="let edu of education" class="education-item-small">
                    <div class="education-year-small">{{ edu.year }}</div>
                    <div [innerHTML]="edu.description"></div>
               </div>
          </div>
     </div>
</div>