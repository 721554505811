import { Component, Input } from '@angular/core';
import { MediaSize, MediaService } from 'src/app/services/media.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-career-history',
  templateUrl: './career-history.component.html',
  styleUrls: ['./career-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden', color: 'white' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms  ease-in'))
    ]),
    trigger('EnterLeaveUp', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [style({ transform: 'translateY(100%)' }), animate('0.5s 00ms ease-in')]),
      transition(':leave', [animate('0.3s ease-out', style({ transform: 'translateY(-100%)' }))])
    ])
  ]
})
export class CareerHistoryComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;

  @Input() inView = false;

  public historyItems = [
    {
      date: 'Sep 2019 - Present',
      title: 'Chief Executive Officer - Uni4 EdTech',
      description: `Uni4 is a global EdTech disruptor rooted in Africa. My mission is to make our world-class online education effortlessly and readily accessible by removing cost and location barriers. Learning is not attained by chance; it must be sought for with ardour and attended to with diligence. I believe that the defeating of barriers is only possible through and investment in knowledge. Change is the end-result of all true learning; we need to prepare for it today. My goal is to build an education front that delivers value across a value chain and imparts tangible skills to people in the workplace. Sophistication is no barrier as we address a spectrum ranging short-learning courses, national certifications and diplomas, as well as degrees. Our operational character is entrepreneurial, experimental, playful, and lean, despite being a global player. Learn as if to live forever, light the fire that develops a passion for learning. Education is life itself.`,
      isOpen: true
    },
    {
      date: 'Nov 2012 - Sep 2019',
      title: 'CEO and Founder - Zapper™',
      description: `We want to revolutionise commerce by making your smartphone more useful to you!`,
      isOpen: false
    },
    {
      date: 'Nov 2012 - Sep 2019',
      title: 'CEO and Founder - ZapZap™ Wallet',
      description: ``,
      isOpen: false
    },
    {
      date: 'March 2006 - Nov 2012',
      title: 'Head of Product - DERIVCO & MICROGAMING',
      description: `<p>Derivco develops world leading online gaming solutions for Microgaming. Microgaming is a privately<br />
                       owned industry leader in innovative gaming solutions.Its software power over 120 casino’s and 40 poker<br />
                       rooms and Microgaming software run the world’s largest poker and progressive jackpot networks.<br />
                       Microgaming is based on the Isle of Man. (www.derivco.com)(www.microgaming.com).</p>

                       <p>Key role leading the business who had achieved significant success in the global market, from a classic<br />
                       start- up to a mature, resilient, world class organisation.</p>

                       <p>International Product Evangelist and primary custodian of the product strategy and roadmap.</p>

                       <p>Key advisor on aquisitions and strategic investments in alligned capability.</p>

                       <p>Conducted strategic analysis of the organisation and created plans for the evolvement of areas such as:<br />
                       talent management, workforce sourcing, organisational structure, software development lifecycle<br />
                       re - engineering, outsourcing, product strategic planning, client engagement models, commercialisation<br />
                       of activities, performance management and the introduction of key performance indicators(KPIs).</p>

                       <p>Led multidisciplined product development and software development teams of 400 people in delivering<br />
                       industry leading client and operator facing software and solutions.</p>

                       <p>Coordinate the delivery of 13 Outsourced Development Teams situated in international locations, to<br />
                       realise innovation and product delivery to an internationally distributed client base.</p>

                       <p>Implemented ITIL, Agile, SCRUM and PRINCE 2 as de facto standards and methodologies.</p>`,
      isOpen: false
    },
    {
      date: 'FEB 1997 - FEB 2006',
      title: 'Head of IT and Strategic Projects (Private Bank) - Investec',
      description: ``,
      isOpen: false
    },
    {
      date: '1996 - 1997',
      title: 'IT / Project / Financial Manager - Distillers Corporation Ltd',
      description: ``,
      isOpen: false
    },
    {
      date: ' 1995',
      title: 'Financial Manager - Niemoller Pharmaceuticals Pty Ltd',
      description: ``,
      isOpen: false
    },
    {
      date: '1990 - 1994',
      title: 'Financial Manager, Retail - Woolworths Ltd (Retail)',
      description: ``,
      isOpen: false
    }
  ];

  public education = [
    {
      year: '2008 - 2008',
      description: 'LDP, Leadership - Gordon Institute of Business Science'
    },
    {
      year: '2002 - 2005',
      description: 'MBA, Business Administration and management - Henley Management College'
    },
    {
      year: '1990 - 1994',
      description: 'Bachelor of Commerce (Law) – University of Johannesburg <br /> Major subjects: Information Systems, Accounting and Law'
    },
    {
      year: '1983 - 1987',
      description: 'Secondary schooling - Randburg High School'
    }
  ];

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
  }

  toggleIsOpen(item: any) {
    // this.historyItems.filter(x => x.isOpen === true).map(x => x.isOpen = false);
    item.isOpen = !item.isOpen;
  }
}
