<div fxLayout="row"
  fxLayoutAlign="space-between stretch"
  class="header-container"
  *ngIf="activeMedia >= size.md">
  <div class="left b">
    <div class="office-div">
    </div>
  </div>
  <div class="overlay right "
    fxLayout="column"
    fxLayoutAlign="center start">
    <div fxLayout="column"
      fxLayoutAlign="center start"
      class="title-wrappers ">
      <span class="g-large-title name-text">
        David De Villiers
      </span>
      <span *ngIf="typewriter_display.length > 0"
        class="g-title sub-title-text">
        {{typewriter_display }}
      </span>
      <p class="david-description">Experienced Ecommerce Practitioner. Currently Leads
        Worldclass Internet based Product Development
        stategy for leading Online Gaming Software provider.
        Financial services specialist. Formerly, head of
        information technology at Investec Private. Led IT and
        business strategy, innovation, development and support
        of systems. In addition, managed international (UK and
        SA) team of IT and business consulting professional.
      </p>
    </div>
  </div>
</div>

<div fxLayout="column"
  fxLayoutAlign="center start"
  *ngIf="activeMedia < size.md">
  <div class="right-small">
    <img class="profile-image-small"
      src="assets/profile-image.png" />
  </div>

  <blockquote fxLayout="column"
    fxLayoutAlign="center start">
    <span class="name-text-small">
      David De Villiers
    </span>
    <span class="sub-title-text-small">
      Senior IT and Strategic Business Professional
    </span>
    <p class="david-description-small">Experienced Ecommerce Practitioner. Currently Leads
      Worldclass Internet based Product Development
      stategy for leading Online Gaming Software provider.
      Financial services specialist. Formerly, head of
      information technology at Investec Private. Led IT and
      business strategy, innovation, development and support
      of systems. In addition, managed international (UK and
      SA) team of IT and business consulting professional.
    </p>
  </blockquote>
</div>