<app-blog-header [imgUrl]="'assets/profile-image.png'"
  [subText]="'Senior IT and Strategic Business Professional'"></app-blog-header>

<div fxLayout="column"
  class="blog-container">
  <div (click)="goBack()"
    class="back-button">
    BACK
  </div>
  <div fxFlex="20px"></div>
</div>

<div *ngIf="activeMedia >= size.md"
  fxLayout="row wrap"
  fxLayoutGap="20px"
  fxLayoutAlign="start space-around"
  class="blog-container">
  <div fxFlex="45%"
    class="blog-item"
    *ngFor="let blog of blogs"
    (click)="goToBlogItem(blog)">
    <div fxLayout="column">
      <img fxFlex="200px"
        class="blog-image"
        [src]="blog.imgUrl" />
      <div class="blog-title">
        {{blog.title}}

        <span class="blog-date">
          - {{blog.dateCreated | date}}
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="activeMedia <= size.sm"
  fxLayout="column"
  fxLayoutAlign="start space-around"
  class="blog-container-small">
  <div class="blog-item"
    *ngFor="let blog of blogs"
    (click)="goToBlogItem(blog)">
    <div fxLayout="column">
      <img fxFlex="200px"
        class="blog-image"
        [src]="blog.imgUrl" />
      <div class="blog-title">
        {{blog.title}}

        <span class="blog-date">
          - {{blog.dateCreated | date}}
        </span>
      </div>
    </div>
  </div>
</div>