import { Component, OnInit } from "@angular/core";
import { MediaService, MediaSize } from 'src/app/services/media.service';

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent implements OnInit {
  public activeMedia: MediaSize;
  public size = MediaSize;


  public skillsInView = false;
  public careerInView = false;
  constructor(private mediaService: MediaService) {

  }

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
  }



  skills() {
    this.skillsInView = true;
  }

  careerHistory() {
    this.careerInView = true;
  }

}
