<app-blog-header [imgUrl]="blog?.imgUrl"
  [subText]="subtext"></app-blog-header>

<div *ngIf="blog && activeMedia >= size.md"
  class="blog-container">
  <div class="blog-item">
    <div class="blog-body">
      <div class="blog-title"
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <span>
          {{blog?.title}}
        </span>
        <div (click)="goBack()"
          class="blog-button">
          BACK
        </div>
      </div>
      <blockquote [innerHtml]="blog?.body">
      </blockquote>
      <div class="quote-links">
        <a target="_blank"
          class="link"
          [href]="link"
          *ngFor="let link of blog?.sharedLinks">read more</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="blog && activeMedia <= size.sm"
  class="blog-container">
  <div class="blog-item">
    <div class="blog-body">
      <div class="blog-title-small"
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <span>
          {{blog?.title}}
        </span>
        <div fxFlex="10px"></div>
        <div (click)="goBack()"
          class="blog-button">
          BACK
        </div>
      </div>
      <blockquote class="blog-body-small"
        [innerHtml]="blog?.body">
      </blockquote>
      <div class="quote-links-small">
        <a target="_blank"
          class="link"
          [href]="link"
          *ngFor="let link of blog?.sharedLinks">read more</a>
      </div>
    </div>
  </div>
</div>