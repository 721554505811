import { Component, Input } from '@angular/core';
import { MediaService, MediaSize } from 'src/app/services/media.service';

@Component({
  selector: 'app-blog-header',
  templateUrl: './blog-header.component.html',
  styleUrls: ['./blog-header.component.scss']
})
export class BlogHeaderComponent {
  @Input() imgUrl: string;
  @Input() subText: string;

  public activeMedia: MediaSize;
  public size = MediaSize;

  private typewriter_text: string = 'Senior IT and Strategic Business Professional       ';
  public typewriter_display: string = '';

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
    this.typingCallback(this);
  }
  typingCallback(that) {
    let total_length = that.typewriter_text.length;
    let current_length = that.typewriter_display.length;
    if (current_length < total_length) {
      that.typewriter_display += that.typewriter_text[current_length];
      setTimeout(that.typingCallback, 100, that);
    }
  }

  goBack() {}
}
