import { Component } from '@angular/core';
import { MediaSize, MediaService } from 'src/app/services/media.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { BlogItem } from 'src/models/blogs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;
  public index = 0;
  blogId: string;

  blog: BlogItem;
  subtext: string;

  constructor(private mediaService: MediaService, private firebaseService: FirebaseService, private router: Router, private route: ActivatedRoute) {
    this.blogId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });

    this.getBlogs();
  }

  goBack() {
    this.router.navigate(['blog']);
  }

  getBlogs() {
    this.firebaseService.getBlogs().subscribe((res) => {
      this.blog = res.find((x) => {
        if (x.id == this.blogId) {
          this.subtext = `${new Date(x.dateCreated).toDateString()}`;
          return x;
        }
      });
    });
  }
}
