<div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.md="center center" class="skills-container" *ngIf="activeMedia > size.md">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.md="center center">
    <div fxFlex="60px"></div>
    <div class="header-text" [@EnterLeave]="'flyIn'">Skills</div>

    <div fxFlex="60px"></div>
    <div fxLayout="column">
      <div fxLayout="row" [@EnterLeave]="'flyIn'">
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/ecommerce.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> ecommerce</span>
            <span>Practitioner</span>
          </div>
        </div>
        <div fxFlex="90px"></div>
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/product_developer.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> Product</span>
            <span>developer</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="60px"></div>
    <div fxLayout="column">
      <div fxLayout="row" [@EnterLeave]="'flyIn'">
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/financial_specialist.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> financial</span>
            <span>specialist</span>
          </div>
        </div>
        <div fxFlex="90px"></div>
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/international_manager.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> international</span>
            <span>manager</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="60px"></div>
    <div fxLayout="column">
      <div fxLayout="row" [@EnterLeave]="'flyIn'">
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/strategist.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> strategist</span>
          </div>
        </div>
        <div fxFlex="90px"></div>
        <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
          <img class="icon-image" src="../../../assets/professional_consulting.svg" />
          <div fxFlex="10px"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="skill-text">
            <span> consulting</span>
            <span>professional</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="180px"></div>
  <app-contact></app-contact>

  <div fxFlex="200px"></div>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" class="skills-container-small" *ngIf="activeMedia <= size.md">
  <div class="header-text-small" [@EnterLeave]="'flyIn'">Skills</div>
  <div fxLayout="column" class="all-the-way">
    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="skill-row">
      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/ecommerce.svg" />
        <div fxFlex="10px"></div>
        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> ecommerce</span>
          <span>proctitioner</span>
        </div>
      </div>

      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/product_developer.svg" />
        <div fxFlex="10px"></div>
        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> Product</span>
          <span>developer</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="skill-row">
      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/financial_specialist.svg" />
        <div fxFlex="10px"></div>
        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> financial</span>
          <span>specialist</span>
        </div>
      </div>

      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/international_manager.svg" />
        <div fxFlex="10px"></div>
        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> international</span>
          <span>manager</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="skill-row" style="margin-bottom: 20px">
      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/strategist.svg" />
        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> strategist</span>
        </div>
      </div>

      <div fxFlex="200px" fxLayout="column" fxLayoutAlign="center center" [@EnterLeave]="'flyIn'">
        <img class="icon-image-small" src="../../../assets/professional_consulting.svg" />

        <div fxLayout="column" fxLayoutAlign="center center" class="skill-text-small">
          <span> consulting</span>
          <span>professional</span>
        </div>
      </div>
    </div>
  </div>
</div>
