import { Component } from '@angular/core';
import { MediaSize, MediaService } from 'src/app/services/media.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { BlogItem } from 'src/models/blogs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;
  public index = 0;

  blogs: BlogItem[] = [];

  constructor(private mediaService: MediaService, private firebaseService: FirebaseService, private router: Router) {}

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });

    this.getBlogs();
  }

  goToBlogs() {
    this.router.navigate(['blog']);
  }
  goBack() {
    this.router.navigate(['']);
  }

  getBlogs() {
    this.firebaseService.getBlogs().subscribe((res) => {
      this.blogs = res;
    });
  }

  goToBlogItem(blog) {
    this.router.navigate([`blog/${blog.id}/view`]);
  }
}
