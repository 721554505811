<div *ngIf="activeMedia > size.md" fxLayout="column" class="contact-container">
  <div (click)="goToBlog()" class="download-cv-button">BLOG</div>
  <div fxFlex="60px"></div>
</div>

<div fxLayout="column" class="contact-container" *ngIf="activeMedia > size.md">
  <div class="header-text">Contact me</div>
  <div fxFlex="60px"></div>
  <div class="contact-item" fxLayout="row">
    <span class="contact-prefix">e</span>
    <div fxFlex="20px"></div>
    <a class="contact-text email" href="mailto:malgas@mweb.co.za">malgas@mweb.co.za</a>
  </div>
  <div fxFlex="20px"></div>
  <div class="contact-item" fxLayout="row">
    <span class="contact-prefix">m</span>
    <div fxFlex="14px"></div>
    <span class=""></span>
    <a class="contact-text email" href="tel:+27823833333">+27 82 383 3333</a>
  </div>
  <div fxFlex="30px"></div>
  <div class="contact-item" fxLayout="row" fxLayoutAlign="start center">
    <img src="../../../../assets/linkedin.PNG" />
    <div fxFlex="10px"></div>
    <span class="contact-text">Follow me</span>
  </div>
  <div fxFlex="50px"></div>
  <div class="download-cv-button" (click)="downloadCV()">download cv</div>
</div>

<div *ngIf="activeMedia <= size.md" fxLayout="column" fxLayoutAlign="center center" class="contact-container-small">
  <div fxFlex="60px"></div>
  <div (click)="goToBlog()" class="download-cv-button">BLOG</div>
  <div fxFlex="20px"></div>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="contact-container-small" *ngIf="activeMedia <= size.md">
  <div class="header-text-small">Contact me</div>
  <div fxLayout="row" fxLayoutAlign="center center" class="contact-item-small">
    <span class="contact-prefix">e</span>
    <div fxFlex="20px"></div>
    <a class="contact-text-small email" href="mailto:malgas@mweb.co.za">malgas@mweb.co.za</a>
  </div>
  <div fxFlex="20px" class="contact-item-small"></div>
  <div fxLayout="row" fxLayoutAlign="center center" class="contact-item-small">
    <span class="contact-prefix">m</span>
    <div fxFlex="14px"></div>
    <a class="contact-text-small email" href="tel:+27823833333">+27 82 383 3333</a>
  </div>
  <div fxFlex="30px"></div>
  <div (click)="openLinkedIn()" fxLayout="row" fxLayoutAlign="center center" class="contact-item-small">
    <img src="../../../../assets/linkedin.PNG" />
    <div fxFlex="10px"></div>
    <div class="contact-text-small">Follow me</div>
  </div>
  <div fxFlex="50px"></div>
  <div class="contact-item-small" fxLayout="row" fxLayoutAlign="center center">
    <div (click)="downloadCV()" class="download-cv-button">download cv</div>
  </div>
  <div fxFlex="50px"></div>
</div>
