import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MediaService, MediaSize } from 'src/app/services/media.service';
import { BlogItem } from 'src/models/blogs';

@Component({
  selector: 'app-quote-area',
  templateUrl: './quote-area.component.html',
  styleUrls: ['./quote-area.component.scss']
})
export class QuoteAreaComponent {
  public activeMedia: MediaSize;
  public size = MediaSize;
  public index = 0;

  blogs: BlogItem[] = [];

  public sliderConfig: any = {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false
    }
  };

  constructor(private mediaService: MediaService, private router: Router) {}

  ngOnInit() {
    this.mediaService.onScreenMedia.subscribe((size: MediaSize) => {
      this.activeMedia = size;
    });
  }

  goToBlogs() {
    this.router.navigate(['blog']);
  }
}
