// MATERIAL DESIGN IMPORTS
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BlogEditComponent } from './components/blog-edit/blog-edit.component';
import { BlogHeaderComponent } from './components/blog-header/blog-header.component';
import { BlogItemComponent } from './components/blog-item/blog-item.component';
import { BlogComponent } from './components/blog/blog.component';
import { CareerHistoryComponent } from './components/career-history/career-history.component';
import { FileUploaderComponent } from './components/file-uploader/file-upload.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { QuoteAreaComponent } from './components/quote-area.component/quote-area.component';
import { ContactComponent } from './components/skills/contact/contact.component';
import { SkillsComponent } from './components/skills/skills.component';

export const sharedComponents = [
  BlogHeaderComponent,
  BlogComponent,
  BlogItemComponent,
  LoginComponent,
  BlogEditComponent,
  HeaderComponent,
  LayoutComponent,
  QuoteAreaComponent,
  SkillsComponent,
  CareerHistoryComponent,
  ContactComponent,
  FileUploaderComponent
];

export const sharedEntryComponents = [FileUploaderComponent];

export const material = [
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatButtonToggleModule,
  MatListModule,
  MatTooltipModule,
  MatFormFieldModule
];
