import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { BlogItem } from 'src/models/blogs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-home',
    templateUrl: 'blog-edit.component.html',
    styleUrls: ['./blog-edit.component.scss']
})

export class BlogEditComponent implements OnInit {
    public selectedBlog: BlogItem;
    public blogs: BlogItem[] = [];
    public Editor = ClassicEditor;
    public selectedFile: File;

    @ViewChild('bodyEditor', { static: false }) bodyEditor;

    constructor(public firebaseService: FirebaseService, private authService: AuthService) { }

    ngOnInit() {
        this.getBlogs();
    }

    ngAfterViewInit() {

    }

    getBlogs() {
        this.firebaseService.getBlogs().subscribe(res => {
            this.blogs = res;
        });
    }

    deleteBlog(blog: BlogItem) {
        if (confirm("are you sure?"))
            this.firebaseService.deleteBlog(blog);
    }

    updateSelectedBlog() {

        this.selectedBlog.body = this.bodyEditor.editorInstance.getData();
        if (this.selectedBlog.id) {
            if (this.selectedFile) {
                this.firebaseService.uploadFileAndAddBlog(this.selectedBlog, this.selectedFile, 'update');
            } else {
                this.firebaseService.updateBlog(this.selectedBlog)
            }

        } else {
            if (this.selectedFile) {
                this.firebaseService.uploadFileAndAddBlog(this.selectedBlog, this.selectedFile, 'create');
            } else {
                this.firebaseService.addBlog(this.selectedBlog);
            }
        }
    }

    setSelectedBlog(blog: BlogItem) {
        this.selectedBlog = blog;
    }

    createNewBlog() {
        this.selectedBlog = {
            title: '',
            body: '',
            dateCreated: new Date().valueOf(),
            imgUrl: '',
            sharedLinks: []
        } as BlogItem;
    }

    clearSelectedBlog() {
        this.selectedBlog = undefined;
    }

    addNewSharedLink() {
        if (this.selectedBlog.sharedLinks) {
            this.selectedBlog.sharedLinks.push('');
        } else {
            this.selectedBlog.sharedLinks = [''];
        }
    }

    removeSharedLink(link: string) {
        this.selectedBlog.sharedLinks.splice(this.selectedBlog.sharedLinks.indexOf(link), 1);
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }

    signOut() {
        this.authService.signOut();
    }

    fileAdded(file: File) {
        this.selectedFile = file;
    }
}