import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { BlogItem } from 'src/models/blogs';
import * as firebase from 'firebase';

@Injectable()
export class FirebaseService {
  private blogsCollectionRef: AngularFirestoreCollection<any>;
  private basePath = '/uploads';
  private uploadTask: any;

  constructor(private db: AngularFirestore) {
    this.blogsCollectionRef = this.db.collection('blogs');
  }

  public getBlogs() {
    const snapShotChange = this.blogsCollectionRef.snapshotChanges();
    return snapShotChange.pipe(
      map((changes) => {
        return changes.map((change) => {
          const data = change.payload.doc.data();
          const id = change.payload.doc.id;

          return { id, ...(data as BlogItem) };
        });
      })
    );
  }

  public async addBlog(item: BlogItem) {
    item.dateCreated = new Date().valueOf();

    const ref = await this.blogsCollectionRef.add(item);
    return ref;
  }

  public deleteBlog(item: BlogItem) {
    this.db.collection('blogs').doc(item.id).delete();
  }

  public updateBlog(item: BlogItem) {
    this.db.collection('blogs').doc(item.id).set(item);
  }

  public uploadFileAndAddBlog(item: BlogItem, file: File, completedAction: 'create' | 'update') {
    let storageRef = firebase.storage().ref();
    this.uploadTask = storageRef.child(`${this.basePath}/${file.name}`).put(file);

    this.uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // * if you want to display progress somewhere.
        // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          item.imgUrl = url;
          if (completedAction === 'create') {
            this.addBlog(item);
          } else {
            this.updateBlog(item);
          }
        });
      }
    );
  }
}
